import React from 'react';
import { Mail, Phone, MapPin, Clock } from 'lucide-react';
import './footer.css';  // Importando el archivo CSS
import ThinkLogo from '../../assets/thinkin/Think_logo.png';
import Pagos from '../../assets/thinkin/imagen-pago.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  
  return (
    <footer className="footer">
      <div className="footer-column">
      <img src={ThinkLogo} alt="Logo" className="logo-image" />
        <div className="follow-us">
          <h4>FOLLOW US</h4>
          <div className="social-icons">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </div>
        </div>
        <div className="subscribe">
      <h4>SUBSCRIBE HERE</h4>
      <form className="subscribe-form">
        <div className='correo'><input type="email" placeholder="Enter your email" /></div>
        <div className='suscribe'><button type="submit">Subscribe</button></div>
      </form>
      <p className="subscribe-text">Subscribe to receive coupon specials, updates and tips on buying great promos.</p>
    </div>
  </div>
  
  <div className="footer-column">
    <h3>RESOURCES</h3>
    <ul>
      <li>ABOUT US</li>
      <li>HELP CENTER</li>
      <li>TURNAROUND TIME OPTIONS</li>
      <li>BLOG</li>
      <li>TEMPLATES</li>
      <li>CUSTOMER REVIEWS</li>
    </ul>
  </div>
  
  <div className="footer-column">
    <h3>COMPANY INFO</h3>
    <ul className="company-info">
      <li><Phone size={16} /> <span>PHONE:</span> 321-423-5144</li>
      <li><Mail size={16} /> <span>EMAIL:</span> info@thinkinstore.com</li>
      <li><MapPin size={16} /> <span>Address:</span> 1201 W Main St, Leesburg, FL 34748</li>
    </ul>
  </div>
  
  <div className="footer-column">
  <div className="hours-header">
    <h6><strong>HOURS:</strong></h6>
    <p>Mon: 9:00 - 5:00</p>
  </div>
  <div className="hours-list">
    <div className="hours-days">
      <p><strong>Tue:</strong> 9:00 - 5:00</p>
      <p><strong>Wed:</strong> 9:00 - 5:00</p>
      <p><strong>Thu:</strong> 9:00 - 5:00</p>
      <p><strong>Fri:</strong> 9:00 - 5:00</p>
      <p><strong>Sat:</strong> 9:00 - 5:00</p>
      <p><strong>Sun:</strong> Closed</p>
    </div>
  
  </div>
  <p className="pickup-note">To Pick Up Products Only By Appointments</p>
</div>
  <div className="footer-bottom">
    <p>© 2022–2024 Thinkinstore / Developed by @Reactiva. All rights reserved.</p>
    <div className="payment-methods">
    <img src={Pagos} alt="Logo" className="logo-image" />
    </div>
  </div>
</footer>
  );
};

export default Footer;