import React, { useEffect, useRef, useState } from 'react';
import './home.css';
import CoffeeSlideshow from './Slideshow';
import RecentlySeen from './recentlySeen';
import logo from '../../assets/thinkin/ABOUTUS_THINKIN.jpg';
import { Clock, Truck, Phone } from 'lucide-react';
import WelcomeModal from './WelcomeModal';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const twoColumnRef = useRef(null);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenModal', 'true');
    }

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (twoColumnRef.current) {
      observer.observe(twoColumnRef.current);
    }

    return () => {
      if (twoColumnRef.current) {
        observer.unobserve(twoColumnRef.current);
      }
    };
  }, []);

  const benefits = [
    { icon: <Clock className="clock-icon" />, title: "FAST TURNAROUND" },
    { icon: <Truck className="truck-icon" />, title: "FAST DELIVERY" },
    { icon: <Phone className="phone-icon" />, title: "FAST SERVICE" },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home-container">
      <WelcomeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={logo}
      />
      <main className="main-content">
        <div className="slideshow-container">
          <CoffeeSlideshow />
        </div>

        <div ref={twoColumnRef} className="two-column-section">
          <div className="column left-column1">
            <div className="card-container">
              <div className="card">
                <h2>Our History</h2>
                <p>
                  Think in Print Shop was born from a dream of two advertisers and a systems engineer, 
                  with intent to offer an efficient and quality service, that provides printing, 
                  photography and service solutions to frame all kinds of images.
                </p>
                <p>
                  Through graphic and advertising design, 
                  we want to offer Americans and Latinos in the United States
                  a close and totally personalized service.
                </p>
                <p>
                  We have started this idea with a working group that has two Publicists specialized 
                  in Graphic Design and Audio-Visual Media and a Systems Engineer Specialist in Project Management, 
                  with a Master's Degree in Software Development and who in turn are the creators of the brand.
                </p> 
              </div>
            </div>
          </div>
          <div className="column right-column1">
            <img className='foto' src={logo} alt="Logo" style={{ width: '124%', height: 'auto', marginLeft:'39%' }} />
          </div>
        </div>

        <div>
          <RecentlySeen/>
        </div>

        <div className="why-choose-us">
          <h2>Why choose Think In Store?</h2>
          <p>Quality custom printing. Guaranteed.</p>
          <div className="benefits">
            {benefits.map((benefit, index) => (
              <button key={index} className="benefit-button">
                <div className="benefit-icon">
                  {React.cloneElement(benefit.icon, { size: 24 })}
                </div>
                <span>{benefit.title}</span>
              </button>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;

