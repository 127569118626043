import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './slideshow.css';
import slide1 from '../../assets/thinkin/slide_1.png';
import slide2 from '../../assets/thinkin/slide_2.png';
import slide3 from '../../assets/thinkin/slide_3.png';
import slide4 from '../../assets/thinkin/slide_4.jpeg';
import slide5 from '../../assets/thinkin/slide_5.jpeg';

const slides = [
  {
    id: 1,
    title: 'Acrylic Logo',
    description: 'crylic Logo with front and back Light',
    image: slide2,
  },
  {
    id: 2,
    title: 'Acrylic 3d',
    description: '3D acrylic printing is a process that combines 3D printing technologies with acrylic materials to create solid objects with high precision and transparent or translucent finishes. It is particularly useful for applications where visual clarity, durability, and aesthetics are important.',
    image: slide1,
  },
  {
    id: 3,
    title: 'Canvas',
    description: 'Custom canvas 20x 24 in ',
    image: slide3,
  },
  {
    id: 4,
    title: 'Business Cards',
    description: 'We create your band dream into a reality',
   image: slide4,
  },
  {
    id: 5,
    title: 'Canvas',
    description: 'Custom canvas 30x 24 in',
    image: slide5,
  },
  // ... otros slides ...
];

const CoffeeSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imageClass, setImageClass] = useState('');
  const [textClass, setTextClass] = useState('');
  const totalSlides = slides.length;

  const nextSlide = () => {
    setImageClass('hide'); // Oculta la imagen hacia abajo
    setTextClass('hide-text'); // Oculta el texto hacia arriba

    setTimeout(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
      setImageClass(''); // Resetea la clase de imagen
      setTextClass(''); // Resetea la clase de texto
    }, 500); // Duración de la animación
  };

  const prevSlide = () => {
    setImageClass('hide'); // Oculta la imagen hacia abajo
    setTextClass('hide-text'); // Oculta el texto hacia arriba

    setTimeout(() => {
      setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
      setImageClass(''); // Resetea la clase de imagen
      setTextClass(''); // Resetea la clase de texto
    }, 500); // Duración de la animación
  };

  useEffect(() => {
    // Reset classes when slide changes
    setImageClass('');
    setTextClass('');
  }, [currentSlide]);

  return (
    <div className="slideshow-container">
      <div className="coffee-slideshow">
        <div className="slideshow-content">
          <div className="slideshow-image-container">
            <img
              src={slides[currentSlide].image}
              alt={slides[currentSlide].title}
              className={`slideshow-image ${imageClass}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/api/placeholder/400/300';
              }}
            />
          </div>
          <div className="divider">
            <div className="thumbnail-container">
              {slides.map((slide, index) => (
                <div
                  key={slide.id}
                  className={`thumbnail ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => {
                    setImageClass('hide');
                    setTextClass('hide-text');
                    setTimeout(() => setCurrentSlide(index), 500);
                  }}
                >
                  <img src={slide.image} alt={slide.title} />
                </div>
              ))}
            </div>
          </div>
          <div className="slideshow-text-content">
            <div className={`slideshow-header ${textClass}`}>
              <div className="slideshow-counter">
                <div className='cantidad'> <span className="current-slide">{`0${currentSlide + 1}`}</span></div>
                <div className='divisor'><span className="divider">/</span></div>
                <div className='cuenta'> <span className="total-slides">{`0${totalSlides}`}</span></div>
              </div>
              <h1 className="slideshow-title">{slides[currentSlide].title}</h1>
            </div>
            <p className={`slideshow-description ${textClass}`}>{slides[currentSlide].description}</p>
            <div className="social-icons">
              <a href="https://www.facebook.com/thinkinstore/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
              <a href="https://www.instagram.com/thinkinstore/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </div>
            <button className="slideshow-button">ORDER NOW</button>
            <div className="slideshow-controls">
              <button onClick={prevSlide} className="slideshow-nav-button">
                <i className="fa-solid fa-arrow-up-long"></i>
              </button>
              <button onClick={nextSlide} className="slideshow-nav-button">
              <i class="fa-solid fa-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoffeeSlideshow;
