


export const OdooConfig = async () => {

  const thinkinstore = {
    host: 'https://dydcliente.dydasesorias.com.co',
    port: '',
    database: 'thinkinstore',
    encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
  }
  return thinkinstore;
}


// Pruebas
/* export const urlFront = 'http://localhost:3000'
export const urlBack = 'http://localhost:3001' */

//Producion
export const urlFront = 'https://thinkinstore.com'
export const urlBack = 'https://back.thinkinstore.com'
