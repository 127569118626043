import Odoo from "odoo-xmlrpc";
import { OdooConfig } from "../utilities/odoo-config.mjs";

export const getCategory = async (callback) => {
    const config = await OdooConfig();
    const args = [[{}]]
    const params = {
        model: "shopweb.webservice",
        method: "get_category",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: 'thinkinstore',
        username: 'admin',
        password: 'admin'
    });

    client.connect((err, response) => {
        console.log("la res", response, err);
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                console.log("la res", response, err);
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });


}

export const getProducts = async (id, callback) => {
    const config = await OdooConfig();
    const args = [[[], id]]
    const params = {
        model: "shopweb.webservice",
        method: "get_product",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: 'thinkinstore',
        username: 'admin',
        password: 'admin'
    });

    client.connect((err, response) => {
        console.log("la res", response, err);
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                console.log("la res", response, err);
                if (err) {
                    callback(err, false);
                    return false;
                }
                return callback(response, true);

            });
        }
    });


}

export const generatePrice = async (variants, callback) => {
    const config = await OdooConfig();
    const args = [[[], variants]]
    const params = {
        model: "shopweb.webservice",
        method: "get_product_product",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: 'thinkinstore',
        username: 'admin',
        password: 'admin'
    });

    client.connect((err, response) => {
        //console.log("la res", response, err);
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                console.log("la res generatePrice", response, err);
                if (err) {
                    callback(err, false);
                    return false;
                }
                return callback(response, true);

            });
        }
    });

}

export const generateInvoice = async (data, callback) => {
    const config = await OdooConfig();
    const args = [[[], data]]
    const params = {
        model: "shopweb.webservice",
        method: "generate_invoice",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: 'thinkinstore',
        username: 'admin',
        password: 'admin'
    });

    client.connect((err, response) => {
        console.log("conect..", response, err);
        if (err) {
            return callback(response, false)
        } else {
            console.log('los args generateInvoice', args);
            
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                console.log("la res invoice", response, err);
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}
