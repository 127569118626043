import './App.css';
import { OdooConfig } from './utilities/odoo-config';
import { useEffect, useState } from 'react';
import Routes from './routes.js';
import Odoo from 'odoo-xmlrpc';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './shared/components/header'; // Importa tu Header
import Footer from './shared/components/footer.js';
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSpinner, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import ShoppingCart from './components/shopping_cart/shoppingCart.js';

function App() {
  const [notificationCount, setNotificationCount] = useState(0);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    const initialiceData = async () => {
      const config = await OdooConfig();
      const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database
      });
      console.log("el odoo", odoo);
    };
    initialiceData();
  }, []);

  useEffect(() => {
    setNotificationCount(sendData.length);
  }, [sendData]);

  

  const toggleCartModal = () => {
    setIsCartModalOpen(!isCartModalOpen);
  };

  const ShoppingCartIconWithBadge = ({ notificationCount, toggleModal }) => {
    return (
      <div onClick={toggleModal} style={{ cursor: 'pointer' }}>
        <Badge badgeContent={notificationCount} color="error">
          <FontAwesomeIcon icon={faShoppingCart} size="2x" />
        </Badge>
      </div>
    );
  };

  return (
    <>
      <Header />

      <Routes toggleCartModal={toggleCartModal}/>

      <div className="floating-icon" onClick={toggleCartModal}>
        <ShoppingCartIconWithBadge notificationCount={notificationCount} toggleModal={toggleCartModal} />
      </div>

      <ShoppingCart isCartModalOpen={isCartModalOpen} toggleCartModal={toggleCartModal} sendData={(data) => setSendData(data)} />

      <Footer />
    </>
  );
}

export default App;
