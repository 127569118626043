import React, { useState, useRef, useEffect } from 'react';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF, PerspectiveCamera } from '@react-three/drei';
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import Interactive3DNeonText from './Interactive3DNeonText';
import '../../components/metodoPago.css';
import { Power } from 'lucide-react';
import './fonts.css'; 


extend({ TextGeometry });

// Configuración de productos
const products = [
  { id: 'pocillo', name: 'Pocillo', model: '/models/plain_mug.glb', defaultPosition: [0, -4, 0], defaultRotation: [0, 0, 0], defaultScale: [60, 60, 60] },
  { id: 'termo', name: 'Termo', model: '/models/thermos_contigo_cup_3d_model.glb', defaultPosition: [0, 0, 0], defaultRotation: [0, 0, 0], defaultScale: [10, 10, 10] },
  { id: 'buso', name: 'Buso', model: '/models/hoodie.glb', defaultPosition: [0, -13, 0], defaultRotation: [0, 0, 0], defaultScale: [10, 10, 10] },
  { id: 'neon', name: 'Neon', model: null },
];

// Componente de renderizado del modelo 3D
const Product3D = ({ productId, color, image }) => {
  const product = products.find(p => p.id === productId);
  const { scene } = useGLTF(product.model);
  const meshRef = useRef();
  const originalMaterials = useRef({});

  useEffect(() => {
    if (scene) {
      // Store original materials
      scene.traverse((node) => {
        if (node.isMesh) {
          originalMaterials.current[node.uuid] = node.material.clone();
        }
      });

      // Apply color or restore original material
      scene.traverse((node) => {
        if (node.isMesh) {
          if (color) {
            node.material.color.set(color);
          } else {
            node.material = originalMaterials.current[node.uuid].clone();
          }
          node.material.needsUpdate = true;
        }
      });

      // Apply image texture if provided
      if (image) {
        const texture = new THREE.Texture(image);
        texture.needsUpdate = true;
        const plane = scene.getObjectByName('plane');
        if (plane) {
          plane.material.map = texture;
          plane.material.needsUpdate = true;
        }
      }
    }
  }, [scene, color, image]);

  if (!product.model) return null;

  return (
    <primitive
      ref={meshRef}
      object={scene.clone()}
      position={product.defaultPosition}
      rotation={product.defaultRotation}
      scale={product.defaultScale}
    >
      {image && (
        <mesh
          name="plane"
          position={[0, 0, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[3, 3, 3]}
        >
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={new THREE.Texture(image)} transparent />
        </mesh>
      )}
    </primitive>
  );
};

// Componente específico para cada producto 3D con su propia cámara
const ProductCanvas = ({ productId, color, image }) => {
  const product = products.find(p => p.id === productId);
  const cameraPosition = {
    pocillo: [0, 0, 40],
    termo: [0, 0, 20],
    buso: [0, 0, 30],
    neon: [0, 0, 30],
  };

  if (!product.model) return null;

  return (
    <Canvas style={{ width: '100%', height: '100%' }}>
      <PerspectiveCamera makeDefault position={cameraPosition[productId]} fov={35} />
      <ambientLight intensity={0.7} />
      <directionalLight position={[10, 10, 10]} intensity={1.5} />
      <Product3D productId={productId} color={color} image={image} />
      <OrbitControls
        enablePan={true}
        minDistance={1}
        maxDistance={50}
        minPolarAngle={0}
        maxPolarAngle={Math.PI}
      />
    </Canvas>
  );
};

// Componente de paleta de colores
const ColorPalette = ({ onColorChange, selectedColor }) => {
  const colors = ['#FF0000', '#FFA500', '#FFFF00', '#008000', '#0000FF', '#800080', '#FFC0CB'];

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
      {colors.map((color, index) => (
        <div
          key={index}
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: color,
            border: color === selectedColor ? '2px solid #FFFFFF' : '1px solid #ccc',
            borderRadius: '50%',
            cursor: 'pointer'
          }}
          onClick={() => onColorChange(color)}
        />
      ))}
    </div>
  );
};
// Componente de carga de imágenes
const ImageUploader = ({ onImageUpload }) => {
  return (
    <div>
      <input 
        type="file" 
        onChange={(event) => {
          const file = event.target.files[0];
          if (file) {
            onImageUpload(file);
          }
        }} 
        accept="image/*"
        style={{
          backgroundColor: '#2A2A2A',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #3A3A3A'
        }}
      />
    </div>
  );
};
// Componente principal de diseño
const SimuladorDiseño = () => {
  const [selectedProduct, setSelectedProduct] = useState(products[0].id);
  const [color, setColor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Estados para las opciones de neón
  const [neonText, setNeonText] = useState('Neon 3D');
  const [neonColor, setNeonColor] = useState('#ff69b4');
  const [neonFont, setNeonFont] = useState('Brush Script MT, cursive');
  const [neonOn, setNeonOn] = useState(true);

  const colorPalette = [
    '#ff69b4', '#00ffff', '#ff00ff', '#ffff00', '#ff4500', '#00ff00'
  ];

  const fonts = [
    { name: 'Brush Script', value: 'Brush Script MT, cursive' },
    { name: 'Jenna Sue', value: '"jennasue", sans-serif' },
    { name: 'Brittany Signature', value: '"brittany-signature", sans-serif' },
    { name: 'Photograph Signature', value: '"photograph signature", sans-serif' },
    { name: 'Wild Script', value: '"wildscript", sans-serif' },
    { name: 'Amanda', value: '"amanda", sans-serif' },
    { name: 'Vintage', value: '"vintage", sans-serif' },
    { name: 'New Cursive', value: '"newcursive", sans-serif' },
    { name: 'Bayshore', value: '"bayshore", sans-serif' },
    { name: 'Barcelona Signature', value: '"barcelonysignature", sans-serif' },
    { name: 'Batman Forever', value: '"batmanforever", sans-serif' },
    { name: 'Neon Glow', value: '"neonglow", sans-serif' },
    { name: 'Roboto Light', value: '"robotoligth", sans-serif' },
    { name: 'Dolce Vita', value: '"dolcevita", sans-serif' },
    { name: 'Red Velvet', value: '"redvelvet", sans-serif' },
    { name: 'Shutter Stone', value: '"shutterstone", sans-serif' },
    { name: 'Youth Syndicate', value: '"youthsyndicate", sans-serif' },
    { name: 'Hesterica', value: '"hesterica", sans-serif' },
    { name: 'Kiona', value: '"kiona", sans-serif' },
    { name: 'Northwell', value: '"northwell", sans-serif' },
    { name: 'Neon Tubes', value: '"neontubes", sans-serif' },
    { name: 'Fab Felt', value: '"fabfelt", sans-serif' },
    { name: 'Hamilton Two', value: '"hamilltontwo", sans-serif' },
    { name: 'ITC Avant', value: '"itcavant", sans-serif' },
    { name: 'Upright', value: '"upright", sans-serif' },
    { name: 'Quinzey', value: '"quinzey", sans-serif' },
    { name: 'Hysteria Bites', value: '"hysteriabites", sans-serif' },
    { name: 'Rocket Cloud', value: '"rocketcloud", sans-serif' },
    { name: 'Seaside Groove', value: '"seasidegroove", sans-serif' },
    { name: 'Brave Light', value: '"braveligth", sans-serif' },
    { name: 'Lovelo', value: '"lovelo", sans-serif' },
    { name: 'Hamilton One', value: '"hamilltonone", sans-serif' },
    { name: 'Neon Lines', value: '"neonlines", sans-serif' },
    { name: 'Sanchez Niu', value: '"sanchezniu", sans-serif' },
    { name: 'Myra Mono', value: '"mymramono", sans-serif' },
    { name: 'Carbono', value: '"carbono", sans-serif' },
    { name: 'Free Spirit', value: '"freespirit", sans-serif' },
    { name: 'Marquee Moon', value: '"marqueemoon", sans-serif' },
    { name: 'Sci-Fi Outline', value: '"scifiedoutline", sans-serif' },
    { name: 'Gruenewald VA', value: '"gruenewaldva", sans-serif' },
    { name: 'Rainbow Colors', value: '"rainbowcolors", sans-serif' },
  ];

  const renderProduct = () => {
    if (selectedProduct === 'neon') {
      return (
        <div style={{ 
          flex: 1, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: '#404040', 
          borderRadius: '10px', 
          padding: '10px',
          height: '400px'
        }}>
          <Interactive3DNeonText 
            text={neonText}
            neonColor={neonColor}
            font={neonFont}
            isOn={neonOn}
          />
        </div>
      );
    } else {
      return (
        <div className="model-viewer" style={{ flex: 1, backgroundColor: '#404040', borderRadius: '10px', padding: '10px' }}>
          <ProductCanvas productId={selectedProduct} color={color} image={selectedImage} />
        </div>
      );
    }
  };

  const renderControls = () => {
    if (selectedProduct === 'neon') {
      return (
        <>
          <div className="mb-4 flex items-center">
            <input
              type="text"
              value={neonText}
              onChange={(e) => setNeonText(e.target.value)}
              className="px-4 py-2 rounded bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-purple-500"
              placeholder="Ingresa tu texto aquí"
            />
            <button
              onClick={() => setNeonOn(!neonOn)}
              className={`ml-4 p-2 rounded-full ${neonOn ? 'bg-green-500' : 'bg-red-500'} focus:outline-none`}
            >
              <Power size={24} color="white" />
            </button>
          </div>
          <div className="mb-4">
            {colorPalette.map((color) => (
              <button
                key={color}
                onClick={() => setNeonColor(color)}
                className="w-8 h-8 rounded-full mx-1 focus:outline-none focus:ring-2 focus:ring-white"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
          <div className="font-selection-container" style={{
            maxHeight: '400px',
            overflowY: 'auto',
            marginRight: '-10px',  // Para compensar el padding del scroll
            paddingRight: '10px',  // Padding para el scroll
          }}>
            <div className="grid grid-cols-2 gap-2">
              {fonts.map((font) => (
                <button
                  key={font.value}
                  onClick={() => setNeonFont(font.value)}
                  className="p-2 rounded border border-white bg-transparent text-white hover:bg-white hover:text-black transition-colors duration-300"
                  style={{ 
                    fontFamily: font.value,
                    fontSize: '14px',  // Tamaño de fuente más pequeño
                    height: '60px',    // Altura fija para todos los botones
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    wordBreak: 'break-word',  // Permite que las palabras largas se rompan
                    overflow: 'hidden',
                  }}
                >
                  {font.name}
                </button>
              ))}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <ColorPalette onColorChange={setColor} selectedColor={color} />
          <ImageUploader onImageUpload={setSelectedImage} />
          {selectedImage && (
            <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#404040', borderRadius: '10px' }}>
              <img src={URL.createObjectURL(selectedImage)} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="checkout-form" style={{ display: 'flex', height: '100vh', backgroundColor: '#000000', color: '#FFFFFF' }}>
      <div className="left-column" style={{ flex: 1, padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <div className="product-selector" style={{ marginBottom: '20px', width: '100%' }}>
          <select 
            value={selectedProduct} 
            onChange={(e) => setSelectedProduct(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#000000',
              color: '#FFFFFF',
              border: '1px solid #FFFFFF',
              borderRadius: '5px',
              fontSize: '16px'
            }}
          >
            {products.map(product => (
              <option key={product.id} value={product.id}>{product.name}</option>
            ))}
          </select>
        </div>
        {renderProduct()}
      </div>

      <div className="right-column" style={{ flex: 1, padding: '20px', backgroundColor: '#1A1A1A', borderRadius: '10px', margin: '20px', display: 'flex', flexDirection: 'column' }}>
        {renderControls()}
      </div>
    </div>
  );
};

export default SimuladorDiseño;