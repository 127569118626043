/**
 * npm install intl -f
 */
export const idClothingBags = 9;
export const idLeftChest = 15;


export const getDate = () => {
    const d = new Date();
    let month = (d.getMonth() + 1)
    let day = d.getDate()
    if (month < 10) {
        month = "0" + (d.getMonth() + 1)
    }
    if (day < 10) {
        day = "0" + d.getDate()
    }
    let fecha = d.getFullYear() + "-" + month + "-" + day
    return fecha
}

export const getDateOdoo = () => {
    const d = new Date();
    let month = (d.getMonth() + 1)
    let day = d.getDate()
    let horas = d.getHours()
    let min = d.getMinutes()
    let seg = d.getSeconds()
    if (month < 10) {
        month = "0" + (d.getMonth() + 1)
    }
    if (day < 10) {
        day = "0" + d.getDate()
    }
    if (horas < 10) {
        horas = "0" + d.getHours()
    }
    if (min < 10) {
        min = "0" + d.getMinutes()
    }
    if (seg < 10) {
        seg = "0" + d.getSeconds()
    }
    let fecha = d.getFullYear() + "-" + month + "-" + day
    let hora = horas + ':' + min + ':' + seg;
    let date = fecha + " " + hora
    //console.log("date ", date)
    return date
}

export const NumberWithCommas = (value) => {
    const numericValue = value.toString().replace(/[^0-9]/g, "");
    return parseInt(numericValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const FormatMoney = (value) => {
    if (value < 0) {
        return '$ -' + NumberWithCommas(value);
    } else {
        return '$ ' + NumberWithCommas(value);
    }
};
export const NumberWithCommasDecimales = (value) => {
    // Convertir a número y redondear a dos decimales
    const numericValue = parseFloat(value).toFixed(2);

    // Separar los miles y agregar comas
    const numberWithCommas = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return numberWithCommas;
};

export const FormatMoneyDecimales = (value) => {
    if (value < 0) {
        return '$ -' + NumberWithCommasDecimales(value);
    } else {
        return '$ ' + NumberWithCommasDecimales(value);
    }
};

export const FormatNumber = (value) => {
    if (value % 1 === 0) {
        // Si no tiene decimales, retornar el número sin .00
        return parseInt(value, 10);
    } else {
        // Si tiene decimales, redondearlo a dos decimales y retornar como cadena
        return parseFloat(value.toFixed(2));
    }
}


export const Capitalize = (value) => {
    return (value.charAt(0).toUpperCase() + value.slice(1));
}


export const verifyData = (type, valor, call) => {
    if (type == "text" || type == "number") {
        if (valor.trim() == "") {
            call("este campo es obligatorio")
        } else {
            call("")
        }
    } else {
        if (valor.trim() == "") {
            call("este campo es obligatorio")
        } else if (!valor.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            call("el formato es incorrecto")
        } else {
            call("")
        }
    }

    //setErrores(objeto)


}

export const FormatDateComplete = function (una_fecha) {
    const fecha = una_fecha ? new Date(una_fecha) : new Date(Date.now());
    const miFecha = fecha.getFullYear() + "-" + (((fecha.getMonth() + 1).toString().length == 1) ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1)) + "-" + (((fecha.getDate()).toString().length == 1) ? "0" + (fecha.getDate()) : (fecha.getDate()));
    return miFecha;
};

export const FormatDateSinHora = function (fecha) {
    console.log("fecha que entra", fecha);

    // Separar la parte de la fecha de la hora (asumiendo que la fecha está en formato 'YYYY-MM-DD HH:mm:ss')
    const fechaSinHoraString = fecha.split(' ')[0];
    const fechaConHora = new Date(fechaSinHoraString);

    // Devolver la fecha formateada sin la hora
    console.log("fecha que sale", fechaSinHoraString);
    return fechaSinHoraString;
};


export const EvaluationResponseOdoo = (res, call) => {
    console.log("EvaluationResponseOdoo ", res)
    if (res?.errores) {
        console.log("res?.errores", res?.errores)
        if (res?.errores?.error) {
            console.log("ingreso al res?.errores?.error")
            if (res?.errores?.error) {
                return call(true, res?.errores?.error)
            }

        } else if (res?.errores?.mensaje) {
            console.log("ingreso al res?.errores?.mensaje", res?.errores?.mensaje)
            return call(true, res?.errores?.mensaje)
        }

        return call(true, "Se presento un error subir los datos")


    } else if (res[0]?.error) {
        console.log("res[0]?.error", res[0]?.error)
        return call(true, res[0]?.error)
    } else if (res[0]?.errores) {
        console.log("res[0]?.errores", res[0]?.errores)
        if (res[0]?.errores?.error) {
            return call(true, res[0]?.errores?.error)
        } else if (res[0]?.errores?.mensaje) {
            return call(true, res[0]?.errores?.mensaje)
        }
        return call(true, "Se presento un error subir los datos")
    } else if (res.idn) {

        if (res.idn?.errores) {
            console.log("res.idn?.errores", res.idn?.errores)
            return call(true, res.idn?.errores)

        }
        //return call(true,"Se presento un error subir los datos")

    } else if (res[0]?.idn) {
        console.log("ingreso al response de id222", res[0].idn)
        console.log("ingreso al response de id", JSON.stringify(res[0].idn)?.includes("error" || "errores"))
        if (JSON.stringify(res[0].idn)?.includes("error" || "errores")) {
            return call(true, JSON.stringify(res[0].idn))

        }


    }

    return call(false)


}

/* 
export const GetPermission = async () => {
    try {
        const granted = await PermissionsAndroid.requestMultiple([
            PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
            PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
        ]);
    } catch (err) {
        console.warn(err);
    }
    const readGranted = await PermissionsAndroid.check(
        PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    );
    const writeGranted = await PermissionsAndroid.check(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    );
    if (!readGranted || !writeGranted) {
        console.log('Sin permisos de lectura y escritura');
        return;
    }
} */