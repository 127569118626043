import React, { useEffect, useState } from 'react';
import { Maximize } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProductoVariante.css';
import { Capitalize, idClothingBags, idLeftChest } from '../../utilities/reactiva';
import { generatePrice, getProducts } from '../../services/services';
import { defaultsDeep } from 'lodash';
import Breadcrumbs from '../../shared/components/Breadcrumbs.js';

const ProductVariant = ({ toggleCartModal }) => {
  const location = useLocation();
  const navigate = useNavigate()
  console.log('location_ProductoVariante...', location.state);
  const { product, categoryName, idRelatedProducts, idCatPadre } = location.state || {};
  const [selectedDimension, setSelectedDimension] = useState(null);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState(idRelatedProducts.includes(product.id) ? { 'Clothing Size': 'XL' } : {});
  const [activeTab, setActiveTab] = useState('description');
  const [selectedImage, setSelectedImage] = useState(0);
  const [showZoom, setShowZoom] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [fileError, setFileError] = useState("");
  const [fileOverLayImage, setFileOverLayImage] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [additionalNote, setAdditionalNote] = useState("");
  const [logoOption, setLogoOption] = useState("no");
  const [productPrice, setProductPrice] = useState(0);
  const [quotation, setQuotation] = useState([])
  const [quantity, setQuantity] = useState(1);
  const [grandTotal, setGrandTotal] = useState(0);
  const [tooltip, setTooltip] = useState({ visible: false, button: '' });
  const [products, setProducts] = useState([])
  const [randomProducts, setRandomProducts] = useState([])
  const defaultImages = ["/api/placeholder/400/400"];// Si no hay producto en el estado, podemos usar imágenes por defecto
  const attributeKeys = product.variante.map(variant => Object.keys(variant.attribute)[0]);


  useEffect(() => {
    window.scrollTo(0, 0);
    loadData()
  }, [])

  useEffect(() => {
    const allSelected = attributeKeys.every(key => selectedAttributes[key]);

    const isDimensionsSelected = attributeKeys[0] === 'Dimensions';
    const hasHeightAndWidth = height && width;

    if (allSelected && (!isDimensionsSelected || (isDimensionsSelected && hasHeightAndWidth))) {
      handleAllAttributesSelected();
    }
  }, [selectedAttributes, height, width]);

  const handleAllAttributesSelected = () => {
    const variants = { product_tmpl_id: product.id, attribute: selectedAttributes }
    console.log("Todos los atributos han sido seleccionados:", variants);
    generatePrice(variants, (res, flag) => {
      console.log("response generatePrice", res);

      if (flag) {
        const precioUnitario = res[0].price;
        const areaCuadrada = height * width;

        if (attributeKeys[0] === 'Dimensions') {
          const total = precioUnitario * areaCuadrada;
          setProductPrice(total);
          setGrandTotal(showFileUpload === false ? total + 20 : total); // Suma 20 si showFileUpload es false
        } else {
          const total = res[0].price;
          setProductPrice(total);
          setGrandTotal(showFileUpload === false ? total + 20 : total); // Suma 20 si showFileUpload es false
        }

        setQuotation(res);
      }
    });
  };


  const loadData = async () => {
    console.log('idRelatedProducts:', idRelatedProducts);
    try {
      const productList = (await Promise.all(
        idRelatedProducts.map(id =>
          new Promise(resolve => {
            getProducts(id, (res, flag) => {
              if (flag) {
                const products = res.map(element => ({
                  id: element.product_tmpl_id,
                  title: element.nombre,
                  description: element.description,
                  image: element.image,
                  variante: element.variante
                }));
                resolve(products);
              } else {
                resolve([]); // Retornar array vacío si no hay productos
              }
            });
          })
        )
      )).flat();
      
      console.log("Lista completa de productos:", productList);
      setProducts(productList);
      setRandomProducts(getRandomProducts(productList));

      const productView = { product, categoryName, idRelatedProducts, idCatPadre };
      const storedProducts = JSON.parse(localStorage.getItem('productsView')) || [];

      // Filtrar para remover el producto actual si ya está en la lista
      const updatedProducts = storedProducts.filter(
        (item) => item.product.id !== product.id
      );
      // Añadir el producto actual al inicio de la lista
      updatedProducts.unshift(productView);

      const limitedProducts = updatedProducts.slice(0, 4);

      localStorage.setItem('productsView', JSON.stringify(limitedProducts));

    } catch (error) {
      console.error('Error al obtener los productos:', error);
    }
  };

  // Convertir la imagen del producto en un array si existe
  const productImages = quotation?.[0]?.image
    ? [`data:image/jpeg;base64,${quotation[0].image}`]
    : product?.image
      ? [`data:image/jpeg;base64,${product.image}`]
      : defaultImages;

  const getRandomProducts = (products, count = 4) => {
    const shuffled = [...products].sort(() => 0.5 - Math.random()); // Mezclar los productos
    return shuffled.slice(0, count); // Tomar los primeros cuatro elementos mezclados
  };

  const handleAttributeSelect = (attributeKey, attributeValue) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeKey]: attributeValue,
      /* height?height:height:null,
      width?width:width:null,  */
    }));

    if (attributeKey === 'Dimensions') {
      setSelectedDimension(attributeValue);
    }
  };


  const handleRadioChange = (event) => {
    setLogoOption(event.target.value);

    const value = event.target.value;
    setShowFileUpload(value === 'no');

    if (value === 'yes') {
      setGrandTotal(prevTotal => prevTotal + 20);
    } else if (value === 'no') {
      setGrandTotal(prevTotal => (prevTotal >= 20 ? prevTotal - 20 : prevTotal));
    }
  };



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('file: ', file);

    // Limite de tamaño en bytes (25 MB)
    const maxSize = 25 * 1024 * 1024;

    // Validación del tipo de archivo (PNG o PDF)
    const validTypes = ["image/png", "application/pdf"];

    if (file) {
      // Verificar tipo de archivo
      if (!validTypes.includes(file.type)) {
        setFileError("Please upload a PNG or PDF file.");
        setUploadedFile(null);
        return;
      }
      // Verificar tamaño del archivo
      if (file.size > maxSize) {
        setFileError("File size exceeds 25 MB limit.");
        setUploadedFile(null);
        return;
      }
      // Convertir archivo a base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Obtiene solo la parte base64
        if (idCatPadre == idClothingBags) {
          setFileOverLayImage(reader.result);
        }

        setUploadedFile(base64String);
        setFilename(file.name)
        setFileError("");
      };
      reader.readAsDataURL(file);
    }
  };


  const handleNoteChange = (event) => {
    setAdditionalNote(event.target.value);
  };


  const sendToBasket = () => {
    const missingFields = [];

    // Verifica cada campo y agrega su nombre al array si está vacío
    const allSelected = attributeKeys.every(key => selectedAttributes[key]);

    const isDimensionsSelected = attributeKeys[0] === 'Dimensions';

    if (!attributeKeys.every(key => selectedAttributes[key])) missingFields.push("Select option");
    if (showFileUpload && !uploadedFile) missingFields.push("Upload file");
    if (isDimensionsSelected && (!height || !width)) missingFields.push("Height or Width");

    // Si hay campos faltantes, muestra el mensaje de alerta
    if (missingFields.length > 0) {
      alert(`Please complete the following fields: ${missingFields.join(", ")}.`);
      return;
    }

    const GrandTotal = parseFloat(grandTotal);
    const newProduct = {
      imageProduct: product.image,
      productId: quotation[0].id,
      amount: quantity,
      title: product.title,
      width: width,
      height: height,
      totalArea: 1,
      price: productPrice,
      notes: additionalNote,
      uploadFile: uploadedFile,
      filename: filename,
      desing: !showFileUpload,
      totalPrice: isNaN(GrandTotal) ? 0 : GrandTotal
    };

    console.log("newProduct", newProduct);

    // Recupera los productos existentes de localStorage
    const existingProducts = JSON.parse(localStorage.getItem('products')) || [];

    // Agrega el nuevo producto a la lista existente
    const updatedProducts = [...existingProducts, newProduct];

    localStorage.setItem('products', JSON.stringify(updatedProducts));


    toggleCartModal();
    cleanProduct();
    loadData();

  };

  const cleanProduct = () => {
    setQuantity(1);
    setWidth('');
    setHeight('');
    setProductPrice(0);
    setUploadedFile(null);
    setShowFileUpload(true);
    setGrandTotal(0);
    setAdditionalNote('')
    setQuotation([]);
    setSelectedAttributes({});
    setLogoOption("no");
  }

  const incrementTotal = () => {
    if (productPrice === 0) {
      showTooltip('+'); // Mostrar tooltip en el botón "+"
      return;
    }
    setQuantity(prevQuantity => prevQuantity + 1);
    setGrandTotal(prevTotal => prevTotal + productPrice);
  };

  const decrementTotal = () => {
    if (productPrice === 0) {
      showTooltip('-'); // Mostrar tooltip en el botón "-"
      return;
    }
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
      setGrandTotal(prevTotal => prevTotal - productPrice);
    }
  };

  const showTooltip = (button) => {
    setTooltip({ visible: true, button });
    setTimeout(() => setTooltip({ visible: false, button: '' }), 3000); // Ocultar después de 3 segundos
  };

  const handleProductClick = (product) => {
    navigate('/products-variant', {
      state: {
        product: product,
        idCatPadre: idCatPadre,
        idRelatedProducts: idRelatedProducts,
        categoryName: categoryName
      }
    });
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };


  return (
    <>
      <div className="page-container">
        <Breadcrumbs />
        {/* Columna Izquierda - Galería de Imágenes */}
        <div className="left-column">
          <div className="main-image-container" style={{ position: 'relative' }}>
            <img
              src={productImages[selectedImage]}
              alt={product?.title || "Product Main"}
              className="main-image"
              style={{ width: '100%', height: 'auto' }}
            />
            {fileOverLayImage && (
              <div
                style={{
                  position: 'absolute',
                  top: product.id == idLeftChest ? '27.7%' : '26.9%',
                  left: product.id == idLeftChest ? '56.5%' : '34.2%',
                  width: product.id == idLeftChest ? '13%' : '31%',
                  height: product.id == idLeftChest ? '13%' : '31%',
                  zIndex: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={fileOverLayImage}
                  alt="Overlay"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            )}
            <button
              onClick={() => setShowZoom(!showZoom)}
              className="zoom-button"
            >
              <Maximize className="w-5 h-5" style={{ color: 'white' }} />
            </button>
          </div>

          <div className="image-gallery">
            {productImages.map((img, index) => (
              <div key={index} className="thumbnail-container" style={{ position: 'relative' }}>
                <img
                  src={img}
                  alt={`Product Thumbnail ${index + 1}`}
                  className={`thumbnail ${selectedImage === index ? 'selected' : ''}`}
                  onClick={() => setSelectedImage(index)}
                />
                {fileOverLayImage && (
                  <div
                    style={{
                      position: 'absolute',
                      top: product.id == idLeftChest ? '25%' : '24.6%',
                      left: product.id == idLeftChest ? '56%' : '34.2%',
                      width: product.id == idLeftChest ? '13%' : '31%',
                      height: product.id == idLeftChest ? '13%' : '31%',
                      zIndex: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={fileOverLayImage}
                      alt="Overlay"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>



        {/* Columna Derecha - Detalles del Producto */}
        <div className="right-column">
          <h1 style={{ color: '#000000' }}>{product?.title || ''}</h1>
          <p style={{ color: '#000000' }}>
            {product?.description || 'Our manufacturing time is a maximum of 15 days + shipping time.'}
          </p>

          {/* Resto del código se mantiene igual... */}

          {/* Los demás componentes permanecen iguales */}
          <div>
            {product.variante.map((variant, variantIndex) => {
              const [attributeKey, attributeValues] = Object.entries(variant.attribute)[0];

              return (
                <div className="option-group" key={variantIndex}>
                  <h3 style={{ color: '#ffffff' }}>{attributeKey}</h3>

                  {/* Renderiza cada opción del atributo como un botón */}
                  {attributeValues.map((attributeValue, index) => {
                    const isSelected = selectedAttributes[attributeKey] === attributeValue;
                    const handleClick = () => handleAttributeSelect(attributeKey, attributeValue);

                    if (attributeKey === 'Color') {
                      return (
                        <button
                          key={`${attributeKey}-${attributeValue}-${index}`}
                          onClick={handleClick} style={{ background: 'none' }}
                        >
                          <div
                            style={{
                              width: '35px',
                              height: '35px',
                              borderRadius: '50%',
                              backgroundColor: attributeValue,
                              border: '1px solid rgba(0,0,0,0.1)',
                              boxShadow: isSelected ? '0 0 0 2.5px white' : 'none'
                            }}
                          />
                          <label style={{
                            color: '#ffffff',
                            marginTop: '5px',
                            fontSize: '15px'
                          }}>
                            {attributeValue}
                          </label>
                        </button>
                      );
                    }

                    return (
                      <button
                        key={`${attributeKey}-${attributeValue}-${index}`}
                        onClick={handleClick}
                        className={isSelected ? "selected" : ""}
                      >
                        {attributeValue}
                      </button>
                    );
                  })}

                  {/* Mostrar inputs si el atributo seleccionado es Dimensions */}
                  {attributeKey === 'Dimensions' && (
                    <div className="dimension-inputs">
                      <label style={{ color: '#ffffff' }}>
                        Width {selectedDimension === 'In' ? 'Inches' : 'Cm'} *
                      </label>
                      <input
                        type="number"
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                        min="0"
                        style={{
                          color: '#000000',            // Texto en negro
                          backgroundColor: '#ffffff',  // Fondo en blanco
                          border: '1px solid #ffffff'  // Borde en blanco
                        }}
                      />

                      <label style={{ color: '#ffffff' }}>
                        Height {selectedDimension === 'In' ? 'Inches' : 'Cm'} *
                      </label>
                      <input
                        type="number"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        min="0"
                        style={{
                          color: '#000000',            // Texto en negro
                          backgroundColor: '#ffffff',  // Fondo en blanco
                          border: '1px solid #ffffff'  // Borde en blanco
                        }}
                      />
                    </div>
                  )}
                </div>

              );
            })}
          </div>

          <div className="option-group">
            <h3>Do you need a New design?</h3>
            <div className="radio-options">
              <label className="radio-label">
                <input
                  type="radio"
                  name="logo"
                  value="yes"
                  checked={logoOption === "yes"}
                  onChange={handleRadioChange}
                />
                Yes + $20.00
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="logo"
                  value="no"
                  checked={logoOption === "no"}
                  onChange={handleRadioChange}
                />
                No
              </label>
            </div>

            {showFileUpload && (
              <div className="file-upload" style={{ color: '#ffffff' }}>
                <h3>Upload your design (PDF - PNG)</h3>
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept=".png,.pdf" // Acepta solo archivos PNG y PDF
                  style={{ marginBottom: '10px' }}
                />
                <p>Upload the file in PNG or PDF formats, max 25 MB.</p>
                {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                {uploadedFile && <p style={{ color: '#ffffff' }}>File uploaded: {filename}</p>}
              </div>
            )}

            <div className="additional-note">
              <h3 style={{ color: '#ffffff' }}>Additional Note</h3>
              <textarea
                placeholder="Enter additional notes here"
                value={additionalNote}
                onChange={handleNoteChange}
              />
            </div>

            {/* <div className="rush-services">
              <h3 style={{ color: '#ffffff' }}>Rush Services (Manufacturing Time)</h3>
              <button>15 Days (Standard)</button>
              <button>7 Days (+10%)</button>
              <button>3 Days (+15%)</button>
            </div> */}

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
              width: '100%'
            }}>
              {/* Información del producto */}
              <div className="product-info">
                <h3 style={{ color: '#ffffff' }}>Unit price: ${productPrice}</h3>
              </div>

              {/* Contador */}
              <div className="counter-container">
                <button className="counter-button" onClick={decrementTotal}>
                  -
                  <div className="tooltip-container">
                    <div className={`tooltip ${tooltip.visible && tooltip.button === '-' ? 'visible' : ''}`}>
                      You must select variants to decrement
                    </div>
                  </div>
                </button>

                <span className="counter-value">{quantity}</span>

                <button className="counter-button" onClick={incrementTotal}>
                  +
                  <div className="tooltip-container">
                    <div className={`tooltip ${tooltip.visible && tooltip.button === '+' ? 'visible' : ''}`}>
                      You must select variants to increment
                    </div>
                  </div>
                </button>
              </div>

              {/* Grand Total */}
              <div className="total">
                <h3 style={{ color: '#ffffff' }}>Grand Total</h3>
                <div style={{ color: '#ffffff' }}>${grandTotal.toFixed(2)}</div>
              </div>
            </div>
          </div>



          <div className="add-to-basket">
            <button onClick={sendToBasket}>Add to Cart</button>
          </div>
        </div>
      </div>

      {/* Modal de Zoom */}
      {showZoom && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            padding: '20px'
          }}
          onClick={() => setShowZoom(false)}
        >
          <div style={{ position: 'relative', maxWidth: '90vw', maxHeight: '90vh' }}>
            <img
              src={productImages[selectedImage]}
              alt={`Zoomed ${product?.title || 'Product'}`}
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
                objectFit: 'contain'
              }}
            />
            {fileOverLayImage && (
              <div
                style={{
                  position: 'absolute',
                  top: product.id == idLeftChest ? '25%' : '24.6%',
                  left: product.id == idLeftChest ? '56.5%' : '34.2%',
                  width: product.id == idLeftChest ? '13%' : '31%',
                  height: product.id == idLeftChest ? '13%' : '31%',
                  zIndex: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={fileOverLayImage}
                  alt="Overlay"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            )}
            <button
              onClick={() => setShowZoom(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'rgba(255, 255, 255, 0.1)',
                border: 'none',
                borderRadius: '50%',
                padding: '8px',
                cursor: 'pointer'
              }}
            >
              <Maximize className="w-6 h-6" style={{ color: 'white' }} />
            </button>
          </div>
        </div>
      )}

      {/* Sección de Descripción con Tabs */}
      <div className="product-tabs">
        <div className="tabs-header">
          <button
            className={`tab-button ${activeTab === 'description' ? 'active' : ''}`}
            onClick={() => setActiveTab('description')}
          >
            Description
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'description' && (
            <div className="description-content">
              <p style={{ color: '#ffffff' }}>
                {product?.description ||
                  'Transform your memories into captivating visual expressions with our custom acrylic logo printing service.'}
              </p>

              <div className="categories-tags">
                <p style={{ color: '#ffffff' }}><strong>Categories:</strong> {categoryName || 'Custom Prints'}</p>
                <p style={{ color: '#ffffff' }}><strong>Tags:</strong> Acrylic, Custom, Logo, Printing</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Productos Relacionados */}
      <div className="related-products">
        <h2 style={{ color: '#ffffff' }}>Related Products</h2>
        <div className="categories-grid">
          {randomProducts.map((product, index) => (
            <div
              key={`${product.id}-${index}`}
              className="category-card"
              onClick={() => handleProductClick(product)}
            >
              <img
                src={`data:image/jpeg;base64,${product.image}`}
                alt={product.title}
                className="category-image"
              />
              <div className="category-overlay" />
              <div className="category-content">
                <h3 className="category-title" style={{ color: '#ffffff' }}>{product.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductVariant;