// App.jsx
import React from 'react';
import './pagina_construccion.css';
import ThinkLogo from '../../assets/thinkin/Think_logo.png';
import { useNavigate } from 'react-router-dom';

const Pagina_construccion = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');  // Replace with your route
  };

  return (
    <div className="container">
      <div className="content">
        {/* Texto principal */}

        {/* Imagen */}
        <div className="image-container">
        <img src={ThinkLogo} alt="Logo" className="logo-image" />
        </div>

        <h4 className="main-title">
        ¡Thank you for visiting us!
        We are currently working hard to create an online experience that meets all your expectations.
        </h4>


        <h4 className="main-title2">
        ¡The page is under construction!
        </h4>
        
        
        {/* Botón */}
        <button className="main-button" onClick={handleClick}>
        Return to homepage
        </button>


      </div>
    </div>
  );
};

export default Pagina_construccion;