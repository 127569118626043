import React, { useState, useRef, useEffect } from 'react';
import './header.css';
import ThinkLogo from '../../assets/thinkin/Think_logo.png';
import { FaShoppingCart, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BadgePoundSterling, PanelsTopLeft, Sticker, SquareUserRound, Shirt, Cake, Box, Car, Megaphone } from 'lucide-react';
import { getCategory } from '../../services/services';

const Header = () => {
  const navigate = useNavigate();
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchRef = useRef(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([{ title: "HOME", url: "/" }]);

  // Fetch categories on component mount
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      await getCategory((res, flag) => {
        console.log("Datos recibidos de la API (categorías):", JSON.parse(res));
        let data = JSON.parse(res);

        if (flag) {
          const updatedMenuItems = data.map((item) => {
            const idsArray = item.subcategoria.map((subcat) => subcat.id);

            return {
              title: item.name,
              id: item.id,
              subcategories: item.subcategoria.map((sub) => ({
                id: sub.id,
                title: sub.name,
                idRelatedProducts: idsArray,
                route: `/CATEGORY-PRODUCTS/`,
              })),
            };
          });

          setMenuItems([{ title: "HOME", url: "/" }, ...updatedMenuItems]);
        } else {
          console.error("Error de getCategory", res);
        }
      });
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryClick = (category, idCatPadre) => {
    navigate(category.route, {
      state: {
        idCatPadre: idCatPadre,
        categoryId: category.id,
        idRelatedProducts: category.idRelatedProducts,
        categoryName: category.title
      }
    });
  };

  const toggleSearch = () => {
    if (searchQuery.trim() === '') {
      setSearchActive(!searchActive);
    } else {
      console.log('Searching:', searchQuery);
    }
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo" onClick={() => navigate('/')}>
          <img src={ThinkLogo} alt="Logo" className="logo-image" />
          <span className="phone">Call us: (321) 423-5144</span>
        </div>
      </div>

      <nav className="menu">
        <ul>
          {menuItems.map((item, index) => (

            <li
              key={index}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
              className={`menu-item ${hoveredItem === index ? 'hovered' : ''}`}
            >
              <a
                className="menu-link"
                onClick={() => item.title == 'HOME' && navigate(item.url)}
              >
                {item.title}
              </a>
              <div className="submenu">
                <div key={index}>
                  {item.subcategories && item.subcategories.length > 0 && hoveredItem === index && (
                    <div>
                      {item.subcategories.map((sub, subCatIndex) => (
                        <a
                          key={subCatIndex}
                          className="submenu-link"
                          onClick={() => handleCategoryClick(sub, item.id)}
                        >
                          {sub.title}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </nav>

      <div className="header-right">
        <div className={`search-container ${searchActive ? 'active' : ''}`} ref={searchRef}>
          <FaSearch className="search-icon" onClick={toggleSearch} />
          <input
            type="text"
            className={`search-input ${searchActive ? 'visible' : ''}`}
            placeholder="Search..."
            onFocus={() => setSearchActive(true)}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;