import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';  
import PlataformaPago from './components/tarjeta_credito/metodoPago.js';
import Header from './shared/components/header.js';
import Home from './components/home/home.js';
import DisenoLogo from './components/Simulador/simulador_diseño.js';
import CompletePage from './components/tarjeta_credito/CompletePage.js';
import Footer from './shared/components/footer'; // Importamos el Footer
import Pagina_construccion from './components/home/pagina_construccion.js';
import ProductVariant from './components/productsVantiants/productovariante.js';
import CategoriasProductos from './components/category-products/category-products.js';

const AppRoutes = ({ toggleCartModal }) => {
    const data = localStorage.getItem('uid');
    console.log("data", data);

    return (
        <>
            <Routes> 
                {/* Ruta para la página de home */}
                <Route path="/" element={<Home />} />
                
                {/* Ruta para la página de completado */}
                <Route path="/complete" element={<CompletePage />} />
                
                {/* Ruta para metodo-pago */}
                <Route path="/metodo-pago" element={<PlataformaPago />} />
                
                {/* Ruta para el simulador */}
                <Route path="/diseno-logo" element={<DisenoLogo />} />

                {/* Ruta para el pagina en construccion */}
                <Route path="/MARKETING-PRODUCTS" element={<Pagina_construccion />} />

                {/* Ruta para el pagina en construccion */}
                <Route path="/SIGNS-BANNERS" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/LABELS-STICKERS" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/PROMOTIONAL-ITEMS" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/CLOTHING & BAGS" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/CATEGORY-PRODUCTS" element={<CategoriasProductos />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/PRODUCTS-VARIANT" element={<ProductVariant  toggleCartModal={toggleCartModal} />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/PARTY-PROPS" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/FOAMS" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/WRAP" element={<Pagina_construccion />} />
                {/* Ruta para el pagina en construccion */}
                <Route path="/PROMO" element={<Pagina_construccion />} />

                {/* Ruta para el pagina en construccion */}
                <Route path="/under_construction" element={<Pagina_construccion />} />
               
                {/* Ruta para manejar errores 404 */}
                <Route path="*" element={<NoMatch />} />
            </Routes>

            
        </>
    );
}

function NoMatch() {
    return (
        <>
            
            <section class="page_404">
                <div>
                    <div class="four_zero_four_bg">
                        <h1>404</h1>
                    </div>
                    <div class="box_404">
                        <h3>
                            <center>Pagina No encontrada</center>
                        </h3>
                    </div>
                    <center><button className='boton404'>
                        <NavLink to={{ pathname: `/` }} style={{ textDecoration: 'none', color: '#ffffff' }}>
                            Ir al Menú
                        </NavLink>
                    </button></center>
                </div>
            </section>
        </>
    );
}

export default AppRoutes;
