import React from 'react';
import './fonts.css';

const Interactive3DNeonText = ({ text, neonColor, isOn, font }) => {
  const getNeonStyle = () => {
    if (!isOn) return {};
    return {
      color: 'white',
      textShadow: `
        0 0 7px ${neonColor},
        0 0 10px ${neonColor},
        0 0 21px ${neonColor},
        0 0 42px ${neonColor},
        0 0 82px ${neonColor},
        0 0 92px ${neonColor},
        0 0 102px ${neonColor},
        0 0 151px ${neonColor}
      `,
    };
  };

  return (
    <div className="relative">
      <h1
        className="text-6xl font-bold"
        style={{
          ...getNeonStyle(),
          fontFamily: font,
          transform: 'perspective(500px) rotateX(20deg)',
        }}
      >
        {text || 'Neon 3D'}
      </h1>
    </div>
  );
};

export default Interactive3DNeonText;