import React, { useState, useEffect } from 'react';
import './shoppingCart.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSpinner, faTrashCan } from '@fortawesome/free-solid-svg-icons';

export default function ShoppingCart(props) {
    const navigate = useNavigate()
    const [tempCart, setTempCart] = useState([]);

    useEffect(() => {
        const storedProducts = JSON.parse(localStorage.getItem('products') || '[]');
        console.log('productos en carrito', storedProducts);
        setTempCart(storedProducts);
        // Enviar los datos al componente padre
        props.sendData(storedProducts);
    }, [props.isCartModalOpen]);


    const handleQuantityChange = (productId, newAmount) => {
        const amount = newAmount === '' ? '' : Math.max(0, parseInt(newAmount) || 0);

        const updatedCart = tempCart.map((item) => {
            if (item.productId === productId) {
                const basePrice = item.price * amount;
                const desingPrice = item.desing ? 20 : 0;
                return {
                    ...item,
                    amount: amount,
                    totalPrice: amount === '' ? 0 : (basePrice + (amount > 0 ? desingPrice : 0)).toFixed(2)
                };
            }
            return item;
        });

        setTempCart(updatedCart);
        localStorage.setItem('products', JSON.stringify(updatedCart));
        props.sendData(updatedCart); // Enviar datos actualizados al padre
    };


    const handleDeleteItem = (productId) => {
        const updatedCart = tempCart.filter(item => item.productId !== productId);
        setTempCart(updatedCart);
        localStorage.setItem('products', JSON.stringify(updatedCart));
        props.sendData(updatedCart); // Enviar datos actualizados al padre
    };

    const calculateTotal = (items) => {
        return items.reduce((total, item) => {
            const basePrice = parseFloat(item.totalPrice) || 0;
            return total + basePrice;
        }, 0).toFixed(2);
    };


    const sentToPay = () => {
        if (tempCart.length === 0) return;

        const cartWithoutImages = tempCart.map(({ imageProduct, ...item }) => item);

        navigate('/metodo-pago', {
            state: {
                product: cartWithoutImages,
                totalToPay: calculateTotal(tempCart)
            }
        });

        props.toggleCartModal()
    };

    return (
        <div className="categories-container">
            {props.isCartModalOpen && (
                <div className="custom-modal-overlay" onClick={props.toggleCartModal}>
                    <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
                        <div className="custom-modal-header">
                            <h5 className='titulo' style={{ fontWeight: 'bold', fontFamily: "Montserrat, sans-serif" }}>Shopping Cart</h5>
                            <button className="custom-modal-close" onClick={props.toggleCartModal}>×</button>
                        </div>
                        <div className="custom-modal-body">
                            {tempCart.length === 0 ? (
                                <div style={{
                                    textAlign: 'center',
                                    padding: '20px',
                                    fontFamily: "Montserrat, sans-serif"
                                }}>
                                    <h4 style={{ color: 'white' }}>Your shopping cart is empty</h4>
                                </div>
                            ) : (
                                <>
                                    <table className="cart-table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Desing</th>
                                                <th>Subtotal</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempCart.map((item, index) => (
                                                <tr key={`${item.productId}-${index}`}>
                                                    <td>
                                                        <div className="product-info">
                                                            <div className="image-column">
                                                                <img
                                                                    src={`data:image/png;base64,${item.imageProduct}`}
                                                                    alt={item.title}
                                                                    className="product-image"
                                                                />
                                                            </div>
                                                            <div className="details-column">
                                                                <div className='text1'>{item.title} door</div>
                                                                <div className='text'>Width: {item.width}</div>
                                                                <div className='text'>Height: {item.height}</div>
                                                                <div className='text'>Total Area (sq. ft.): {item.totalArea}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text1'>${item.price.toFixed(2)}</td>
                                                    <td>
                                                        <input
                                                            className='text2'
                                                            type="number"
                                                            value={item.amount}
                                                            onChange={(e) => handleQuantityChange(item.productId, e.target.value)}
                                                            min="0"
                                                            style={{ width: '77px', height: '46px' }}
                                                        />
                                                    </td>
                                                    <td className='text1'>
                                                        {item.desing ? (
                                                            <span style={{ color: 'green' }}>+$20</span>
                                                        ) : (
                                                            <span style={{ color: 'gray' }}>No</span>
                                                        )}
                                                    </td>
                                                    <td className='text1'>${item.totalPrice}</td>
                                                    <td>
                                                        <button className="delete-button" onClick={() => handleDeleteItem(item.productId)}>
                                                            <FontAwesomeIcon icon={faTrashCan} size="2x" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="cart-totals">
                                        <h3>Cart totals</h3>
                                        <div className="total-row">
                                            <span style={{ borderTopLeftRadius: '5px' }}>Subtotal</span>
                                            <span style={{ borderTopRightRadius: '5px' }}>${calculateTotal(tempCart)}</span>
                                        </div>
                                        <div className="line"></div>
                                        <div className="total-row">
                                            <span style={{ borderBottomLeftRadius: '5px' }}>Total</span>
                                            <span style={{ borderBottomRightRadius: '5px' }}>${calculateTotal(tempCart)}</span>
                                        </div>
                                        <button className="view-button" onClick={sentToPay} >VIEW</button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}