import React, { useState, useEffect, useCallback, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import '../Stripe.css';
import { useLocation } from 'react-router-dom';
import { generateInvoice } from '../../services/services';
import { urlBack, urlFront } from '../../utilities/odoo-config.mjs';


const STRIPE_PUBLIC_KEY = "pk_test_51Pznpc04IL6jeGW2CLdolvpD5a4Inq3gF7vgUydAVWKBOPclrlPqkvhEMSwlgd55DUKtSHIg7ppVLl5327qDTkjR00bSeIzgTu";
const PAYMENT_METHODS = ['card', 'afterpay_clearpay', 'affirm', 'klarna'];
const INITIAL_FORM_DATA = {
  email: '',
  country: 'Colombia',
  firstName: '',
  lastName: '',
  cedula: '',
  address: '',
  additionalAddress: '',
  city: '',
  department: 'Caldas',
  postalCode: '',
  phone: '',
  saveInfo: false
};

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const darkModeStyles = `
  body {
    background-color: #0A2540;
    color: #FFFFFF;
  }
  .dark-mode {
    background-color: #d3d3d3;
    color: #FFFFFF;
  }
  .dark-mode .form-control,
  .dark-mode .form-select {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #a1a1a1;
    color: #FFFFFF;
    transition: all 0.3s ease;
    border-radius: 0;
    padding: 0.375rem 0.75rem;
  }
  .dark-mode .form-control:focus,
  .dark-mode .form-select:focus {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border-bottom-color: transparent;
  }
  .dark-mode .form-control::placeholder {
    color: transparent;
  }
  .dark-mode h2 {
    color: #FFFFFF;
  }
  .dark-mode .btn-primary {
    background-color: #efe407;
    border-color: #efe407;
    color:#000000;
    font-weight: 700;
  }
  .dark-mode .btn-primary:hover {
    background-color: #5851E5;
    border-color: #5851E5;
  }
  .gradient-border {
    position: absolute;
    top: 112px;
    bottom: 0;
    left: 50%;
    width: 2px;
    background: linear-gradient(to bottom, #FFD700, #FFFFFF, #404040);
  }
  .input-wrapper {
    position: relative;
    margin-bottom: 1rem;
  }
  .input-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, #FFD700, #000000, #404040, #FFD700);
    transition: width 0.3s ease;
    background-size: 200% 100%;
  }
  .input-wrapper:focus-within::after {
    width: 100%;
    animation: gradient-shift 2s linear infinite;
  }
  .floating-label {
    position: absolute;
    left: 0.75rem;
    top: 0.75rem;
    font-size: 1rem;
    color: #8792A2;
    transition: all 0.3s ease;
    pointer-events: none;
    padding: 0 0.25rem;
  }
  .form-control:focus ~ .floating-label,
  .form-control:not(:placeholder-shown) ~ .floating-label {
    top: -0.5rem;
    left: 0.5rem;
    font-size: 0.75rem;
    color: #FFFFFF;
  }
  .stripe-form-wrapper {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.05);
    transition: box-shadow 0.3s ease;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .stripe-form-wrapper:focus-within {
    box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.5), 0 0 0 4px rgba(0, 0, 0, 0.5), 0 0 0 6px rgba(64, 64, 64, 0.5);
  }
  .stripe-form-wrapper::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: linear-gradient(to right, #FFD700, #000000, #404040, #FFD700);
    border-radius: 10px;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .stripe-form-wrapper:focus-within::before {
    opacity: 1;
    animation: gradient-shift 2s linear infinite;
  }
  @keyframes gradient-shift {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

const CheckoutForm = ({ onSubmit, formClient, product, totalToPay }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);

    const clientData = {
      email: formClient.email,
      townCity: formClient.city,
      state: formClient.department,
      country: 'Colombia',
      phone: formClient.phone,
      firstName: formClient.firstName,
      lastName: formClient.lastName,
      streetAddress: formClient.address
    };

    const dataToSend = {
      client: clientData,
      product: product
    };

    localStorage.setItem('dataToSend', JSON.stringify(dataToSend));

    // Confirmar el pago
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${urlFront}/complete`,
      },
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    }

    onSubmit({ error });
  };

  const isFormComplete = formClient?.firstName && formClient?.lastName && formClient?.email && formClient?.address && formClient?.city && formClient?.phone;

  return (
    <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <PaymentElement />

      {error && <div className="text-danger">{error}</div>}

      <label style={{ fontSize: '30px', fontFamily: 'sans-serif', marginTop: '-30px' }}>
        ${totalToPay}
      </label>

      <button
        type="submit"
        disabled={!stripe || processing || !isFormComplete}
        className="btn btn-primary mt-4"
      >
        {processing ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
};

const PlataformaPago = () => {
  const location = useLocation();
  const product = location.state?.product;
  const totalToPay = location.state?.totalToPay;
  const [clientSecret, setClientSecret] = useState(null);
  const [supportedPaymentMethods, setSupportedPaymentMethods] = useState([]);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [error, setError] = useState(null);
  const stripeFormRef = useRef(null);

  useEffect(() => {
    console.log('producto en pagos:', product, 'totalToPay: ', totalToPay);

    const styleElement = document.createElement('style');
    styleElement.textContent = darkModeStyles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      if (stripeFormRef.current) {
        stripeFormRef.current.classList.add('focus-within');
      }
    };

    const handleBlur = (e) => {
      if (stripeFormRef.current && !stripeFormRef.current.contains(e.relatedTarget)) {
        stripeFormRef.current.classList.remove('focus-within');
      }
    };

    const stripeForm = stripeFormRef.current;
    if (stripeForm) {
      stripeForm.addEventListener('focusin', handleFocus);
      stripeForm.addEventListener('focusout', handleBlur);
    }

    return () => {
      if (stripeForm) {
        stripeForm.removeEventListener('focusin', handleFocus);
        stripeForm.removeEventListener('focusout', handleBlur);
      }
    };
  }, [clientSecret]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  //-------------_---------------_----------------_--------------_----------------------
  /* const submitForm = async (event) => {
    event.preventDefault();

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.address || !formData.city || !formData.phone) {
      alert("Please fill in all required fields.");
      return;
    }

    console.log("Form data submitted:", formData);
    alert("Form submitted successfully!");

    const clientData = {
      email: formData.email,
      townCity: formData.city,
      state: formData.department,
      country: 'Colombia',
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      streetAddress: formData.address
    };

    const dataToSend = {
      client: clientData,
      product: product
    }

    await generateInvoice(dataToSend, (res, flag) => {
      console.log('response...', res);

      if (flag) {

      }
    });


    // Limpiar el formulario después del envío
    setFormData(INITIAL_FORM_DATA);
  }; */

  //-------------_---------------_----------------_--------------_----------------------
  const fetchClientSecret = useCallback(async () => {
    if (!formData.email) return;

    try {
      // Convertir totalToPay a número
      const totalToPayNumeric = parseFloat(totalToPay);

      // Verificar y filtrar los métodos de pago según el total
      const updatedPaymentMethods = totalToPayNumeric < 50
        ? PAYMENT_METHODS.filter(method => method !== 'affirm')
        : PAYMENT_METHODS;

      const response = await axios.post(`${urlBack}/api/checkout`, {
        amount: totalToPayNumeric * 100, // Stripe exige convertirlo a centavos
        email: formData.email,
        paymentMethods: updatedPaymentMethods,
      });

      if (response.data.clientSecret) {
        setClientSecret(response.data.clientSecret);
        setSupportedPaymentMethods(response.data.supportedPaymentMethods);
        setError(null);
      } else {
        throw new Error('No se recibió clientSecret del servidor');
      }
    } catch (err) {
      console.error("Error al obtener el client secret:", err);
      setError(err.response?.data?.message || 'Error processing the payment.');
      setClientSecret(null);
      setSupportedPaymentMethods([]);
    }
  }, [formData.email]);

  useEffect(() => {
    if (formData.email) {
      fetchClientSecret();
    }
  }, [formData.email, fetchClientSecret]);

  const handlePaymentResult = (result) => {
    if (result.error) {
      setError(result.error.message);
    }
  };

  const stripeOptions = clientSecret ? {
    clientSecret,
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#efe407',
        colorBackground: '#ffffff00',
        colorText: '#000000',
        colorDanger: '#FF5252',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
        borderBottom: '2 px solid #ffffff'
      },
      rules: {
        '.Input': {
          borderBottom: '2px solid #ffffff',
          color: '#ffffff'

        },     // See all supported class names and selector syntax below
        '.Label': {
          color: '#ffffff',

        },
        '.Tab': {
          color: '#ffffff',
          border: '1px solid #ffffff',

        },
      }


    },
    locale: 'en',
  } : {};

  const renderInput = (name, placeholder, type = 'text', required = true) => (
    <div className="input-wrapper mb-3">
      <input
        type={type}
        className="form-control"
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        placeholder=" "
        required={required}
      />
      <label className="floating-label">{placeholder}</label>
    </div>
  );

  const renderContactForm = () => (
    <form className="contact-shipping-form" /* onSubmit={submitForm} */>
      <h2 className="mb-4">Contact and Delivery</h2>
      <div className="input-wrapper mb-3">
        <select className="form-select" name="country" value={formData.country} onChange={handleInputChange}>
          <option value="Colombia">Colombia</option>
        </select>
      </div>
      <div className="row mb-3">
        <div className="col">
          {renderInput('firstName', 'First Name')}
        </div>
        <div className="col">
          {renderInput('lastName', 'Last Name')}
        </div>
      </div>
      {renderInput('email', 'Email')}
      {renderInput('address', 'Address')}
      {renderInput('additionalAddress', 'House, apartment, etc. (optional)', 'text', false)}
      {renderInput('city', 'City')}
      <div className="row mb-3">
        <div className="col">
          <div className="input-wrapper">
            <select className="form-select" name="department" value={formData.department} onChange={handleInputChange}>
              <option value="Caldas">Caldas</option>
              {/* Agregar otros departamentos si es necesario */}
            </select>
          </div>
        </div>
        <div className="col">
          {renderInput('postalCode', 'Postal Code (optional)', 'text', false)}
        </div>
      </div>
      {renderInput('phone', 'Phone', 'tel')}
      {/* <div className="input-wrapper mt-4">
        <button type="submit" className="btn btn-primary">Submit</button>
      </div> */}
    </form>
  );

  const renderPaymentForm = () => (
    <div className="stripe-form-container" style={{ height: '100%' }}>
      {error && <p className="text-danger text-center">{error}</p>}
      {clientSecret ? (
        <div className="stripe-form-wrapper" ref={stripeFormRef} style={{ height: '100%', minHeight: '400px' }}>
          <Elements stripe={stripePromise} options={stripeOptions}>
            <CheckoutForm onSubmit={handlePaymentResult} formClient={formData} product={product} totalToPay={totalToPay} />
          </Elements>
        </div>
      ) : (
        <p className="text-white text-center">
          {formData.email ? 'Loading payment form...' : 'Please complete the contact information to load the payment form.'}
        </p>
      )}
    </div>
  );

  return (
    <div className="container-fluid py-5 dark-mode" style={{ backgroundColor: '#d3d3d3', position: 'relative', minHeight: '100vh' }}>
      <div className="gradient-border"></div>
      <div className="row" style={{ height: '100%' }}>
        <div className="col-md-6">
          {renderContactForm()}
        </div>
        <div className="col-md-6" style={{ height: '100%' }}>
          {renderPaymentForm()}
        </div>
      </div>
    </div>
  );
};

export default PlataformaPago;