import React from 'react';
import { X } from 'lucide-react';
import './WelcomeModal.css';
import { fontGrid } from '@mui/material/styles/cssUtils';

const WelcomeModal = ({ isOpen, onClose, imageSrc }) => {
  if (!isOpen) return null;

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const contentStyle = {
    backgroundColor: '#d3d3d3',
    padding: '46px',
    borderRadius: '8px',
    maxWidth: '90%',
    width: '630px',
    position: 'relative',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '-7px',
    color: '#000000',
    right: '-1px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSsize: '24px',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    marginBottom: '20px',
  };


  const textoStyle2 = {
    color: '#000000',
    fontWeight: '500',
    animation: 'fadeIn 1.5s ease-in-out',
    display: 'block',
    marginBottom: '20px',
  };

  const exploreButtonStyle = {
    width: '100%',
    padding: '10px',
    backgroundColor: '#000000',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
  };

  return (
    <div style={modalStyle}>
      <div style={contentStyle}>
        <button onClick={onClose} style={closeButtonStyle}>
          <X size={24} />
        </button>
        <img src={imageSrc} alt="Welcome" style={imageStyle} />
        
        <style>{`
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
        `}</style>

        <div className='modal-title'>
        ¡Page currently under construction! 
        </div>
        
        <div style={textoStyle2}>
        
        you can explore some of our products.
    If you need additional information about other products currently not on the page, you can contact us at:
    Email: <a className='info' href="mailto:info@thinkinstore.com">info@thinkinstore.com</a>
    Call us: <a className='info' href="https://wa.me/3214235144?text=Hola%20necesito%20información">Call us: (321) 423-5144</a>
        </div>
        
        <button onClick={onClose} style={exploreButtonStyle}>
          Explorar
        </button>
      </div>
    </div>
  );
};

export default WelcomeModal;