import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../components/breadcrumbs.css';

const Breadcrumbs = () => {
  const location = useLocation();
  const { state } = location;

  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <nav aria-label="breadcrumb" className="mx-4 my-2">
      <ol className="breadcrumb flex items-center space-x-2 text-sm">
        <li className="breadcrumb-item">
          <Link to="/" className="text-blue-600 hover:text-blue-800 font-medium" style={{color:'rgb(131 131 131)'}}>
            Home
          </Link>
        </li>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          if (name === 'CATEGORY-PRODUCTS') {
            return (
              <React.Fragment key={name}>
                <li className="breadcrumb-separator text-gray-500">/</li>
                <li className="breadcrumb-item" >
                  <Link 
                    to="/CATEGORY-PRODUCTS"
                    state={state}
                    style={{color:'#000000'}}
                  >
                    {state?.categoryName || 'Categorías'}
                  </Link>
                </li>
              </React.Fragment>
            );
          }

          if (name === 'PRODUCTS-VARIANT') {
            return (
              <React.Fragment key={name}>
                <li className="breadcrumb-separator text-gray-500">/</li>
                <li className="breadcrumb-item">
                  <Link 
                    to="/CATEGORY-PRODUCTS"
                    state={{
                      categoryId: state?.categoryId,
                      categoryName: state?.categoryName,
                      idRelatedProducts: state?.idRelatedProducts
                    }}
                    className="text-yellow-600 hover:text-blue-800 font-medium" style={{color:'rgb(131 131 131)'}}
                  >
                    {state?.categoryName || 'Categorías'}
                  </Link>
                </li>
                <li className="breadcrumb-separator text-gray-500">/</li>
                <li className="breadcrumb-item">
                  <span className="text-gray-700" style={{color:'#000000',fontWeight:'700'}}>
                    {state?.product?.title || 'Producto'}
                  </span>
                </li>
              </React.Fragment>
            );
          }


          return (
            <React.Fragment key={name}>
              <li className="breadcrumb-separator text-gray-500">/</li>
              {isLast ? (
                <li className="breadcrumb-item">
                  <span className="text-gray-700"  >
                    {name}
                  </span>
                </li>
              ) : (
                <li className="breadcrumb-item">
                  <Link 
                    to={routeTo}
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    {name}
                  </Link>
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

