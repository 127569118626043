import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getProducts } from '../../services/services';
import './category-products.css';
import Breadcrumbs from '../../shared/components/Breadcrumbs';

export default function CategoriasProductos() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const categoryId = location.state?.categoryId || location.state?.idCatPadre;
  const categoryName = location.state?.categoryName;
  const idRelatedProducts = location.state?.idRelatedProducts;

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (categoryId) {
      fetchProducts();
    } else {
      setError('No se proporcionó un ID de categoría');
      setIsLoading(false);
    }
  }, [categoryId]);

  const fetchProducts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const productList = await new Promise((resolve, reject) => {
        getProducts(categoryId, (res, flag) => {
          if (flag && Array.isArray(res)) {
            resolve(res.map(element => ({
              id: element.product_tmpl_id,
              title: element.nombre,
              description: element.description,
              image: element.image,
              variante: element.variante
            })));
          } else {
            reject('La respuesta de la API no es válida');
          }
        });
      });
      setProducts(productList);
    } catch (error) {
      setError('Error al obtener los productos: ' + error.message);
      console.error('Error al obtener los productos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductClick = (product) => {
    navigate('/PRODUCTS-VARIANT', {
      state: {
        product: product,
        categoryId: categoryId,
        idRelatedProducts: idRelatedProducts,
        categoryName: categoryName,
      }
    });
  };

  const ErrorMessage = ({ message, showRetry = true }) => (
    <div className="error-container">
      <h2>Error</h2>
      <p>{message}</p>
      {showRetry && (
        <button onClick={() => navigate('/')} className="retry-button">
          Volver al inicio
        </button>
      )}
    </div>
  );

  if (!categoryId) {
    return <ErrorMessage message="No se encontró la categoría" />;
  }

  return (
    <div className="categories-container">
      <Breadcrumbs />
      
      <div className="categories-header">
        <h1 className="categories-title">
          {categoryName || 'Productos'}
        </h1>
        
        <p className="categories-description">
          {categoryName} are visual or graphic elements created using acrylic material 
          and printed with a specific design, typically a logo.
        </p>
      </div>

      <div className="categories-main-content">
        {isLoading ? (
          <div className="loading-spinner">Cargando productos...</div>
        ) : error ? (
          <ErrorMessage message={error} showRetry={false} />
        ) : products.length === 0 ? (
          <div className="error-message">No hay productos en esta categoría</div>
        ) : (
          <div className="categories-grid">
            {products.map((product, index) => (
              <div
                key={`${product.id}-${index}`}
                className="category-card"
                onClick={() => handleProductClick(product)}
              >
                <img 
                  src={`data:image/jpeg;base64,${product.image}`}
                  alt={product.title}
                  className="category-image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'ruta-a-tu-imagen-por-defecto.jpg';
                  }}
                />
                <div className="category-overlay" />
                <div className="category-content">
                  <h3 style={{ color: '#ffffff' }}>{product.title}</h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

