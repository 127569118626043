import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './recentlySeen.css';

const RecentlySeen = () => {
    const navigate = useNavigate()
    const [storedProducts, setStoredProducts] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const productsView = JSON.parse(localStorage.getItem('productsView')) || [];
        console.log("ProductsView...",productsView);

        setStoredProducts(productsView)
    }

    const handleProductClick = (product) => {
        navigate('/products-variant', {
            state: {
                product: product.product,
                idCatPadre: product.idCatPadre,
                idRelatedProducts: product.idRelatedProducts,
                categoryName: product.categoryName
            }
        });
    };

    return (
        <div className="recent-products">
            <h2 style={{ color: '#ffffff' }}>Recently Seen</h2>
            <div className="categories-grid">
                {storedProducts && storedProducts.length > 0 ? (
                    storedProducts.map((product, index) => (
                        <div
                            key={`${product.product.id}-${index}`}
                            className="category-card"
                            onClick={() => handleProductClick(product)}
                        >
                            <img
                                src={`data:image/jpeg;base64,${product.product.image || ''}`}
                                alt={product.product.title || 'Product Image'}
                                className="category-image"
                            />
                            <div className="category-overlay" />
                            <div className="category-content">
                                <h3 className="category-title" style={{ color: '#ffffff' }}>
                                    {product.product.title || 'No Title'}
                                </h3>
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ color: '#ffffff', textAlign:'center' }}>
                        No recent products found.
                    </div>
                )}
            </div>
        </div>

    );
};

export default RecentlySeen;
